<template>
  <div>
    <div v-if="!isLogin">
      <div
          style="height: 56px;width: 100%;background-color: #0fb08d;text-align: center;line-height: 56px;font-size:24px;color: white;"
          @click="login">
        点击注册/登录
      </div>
    </div>
    <router-view/>
    <van-tabbar v-model="active" active-color="#E66C34" inactive-color="#000" route @change="onChange">
      <van-tabbar-item icon="home-o" name="home" replace to="/home/home">首页</van-tabbar-item>
      <van-tabbar-item icon="search" name="square" replace to="/home/square">康复圈</van-tabbar-item>
      <!--      <van-tabbar-item name="person" icon="friends-o" replace to="/home/person">个人中心</van-tabbar-item>-->
    </van-tabbar>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
      active: 'home'
    }
  },
  computed: {
    isLogin () {
      return this.$store.state.user.profile.isLogin
    }
  },
  mounted () {
    console.log('登录用户', this.$store.state.user)
    this.initIM()
  },
  methods: {
    login () {
      this.$router.push('/login/index')
    },
    initIM () {
    },
    onChange (val) {
      // this.$toast(val)
    }
  }
}
</script>

<style scoped>

</style>
