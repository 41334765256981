<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="训练方案记录"/>
    <div style="margin-top: 56px">
      <!--  医生列表 用vant组件 -->
      <!--  loading  -->
      <div v-show="!datas" style="text-align: center;margin-top: 16px">
        <van-loading/>
      </div>
      <div v-if="datas" style="padding: 16px">
        <div style="font-size: 20px">方案定制数量: {{ datas.length }}</div>
      </div>
      <van-cell-group v-show="datas">
        <van-cell
          v-for="(item, index) in datas"
          :key="index"
          :label="`医生-患者: ${item.doctorName} - ${item.patientName}`"
          :title="`方案: ${item.plan.planTitle}`"
          :value="`时间: ${DateUtils.formattedTime(item.createDate)}`"
          @click="onItemClick(item)"
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'
import DateUtils from '@/utils/DateUtils'

export default {
  name: 'AdminHospitalPlanList',
  components: { MyNavBar },
  data () {
    return {
      datas: null
    }
  },
  mounted () {
    this.loadDatas()
  },
  methods: {
    async loadDatas () {
      const datas = await AdminHospitalDao.planList()
      for (let i = 0; i < datas.length; i++) {
        const data = datas[i]
        data.plan = JSON.parse(data.plan)
      }
      console.log(datas)
      this.datas = datas
    },
    onItemClick (item) {
      this.$store.commit('play/setPlan', item)
      this.$router.push('/PlanDetailPage')
    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
