<template>
  <div>
    <MyNavBar :title="'康复类型选择'"/>
    <div style="padding: 16px">
      <div v-for="(item) in datas" :key="item.id">
        <div
          @click="onItemClick(item)"
          style="padding: 16px;text-align: center;font-size: 20px;margin-top: 8px;border-radius: 8px"
          :style="item.selected?'background-color: #00b667':'background-color: #f6f6f6'">
          {{ item.name }}
        </div>
      </div>
      <div style="height: 80px"></div>
      <div
        style="position: fixed;bottom: 16px;left:16px;right:16px;border-radius: 24px;box-sizing: border-box;display:block;border-radius: 24px;padding: 16px;background-color: #0d3d3d;color: white;margin-top: 32px;text-align: center;font-size: 18px"
        @click="submit">
        下一步
      </div>
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import RegisterDao from '../../network/RegisterDao'
import { Notify } from 'vant'

export default {
  name: 'SickKindSelectPage',
  components: { MyNavBar },
  data () {
    return {
      datas: [],
      selected: null
    }
  },
  mounted () {
    this.loadSickKind()
  },
  methods: {
    async loadSickKind () {
      var sickKinds = await RegisterDao.getSickKind()
      sickKinds.forEach(e => {
        e.selected = false
      })
      this.datas = sickKinds
    },
    onItemClick (item) {
      if (this.selected) {
        this.selected.selected = false
      }
      item.selected = true
      this.selected = item
    },
    submit () {
      if (!this.selected) {
        Notify('请选择一个康复类型')
        return
      }
      // console.log(this.selected)
      this.$store.commit('login/setSickKind', this.selected.id)
      console.log(this.$store.state.login)
      this.$router.push('/login/userBaseInfoEntryPage')
    }
  }
}
</script>

<style scoped>

</style>
