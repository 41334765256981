import { render, staticRenderFns } from "./SickKindSelectPage.vue?vue&type=template&id=13fc6c3a&scoped=true"
import script from "./SickKindSelectPage.vue?vue&type=script&lang=js"
export * from "./SickKindSelectPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13fc6c3a",
  null
  
)

export default component.exports