<template>
  <div class="page-box">
    <div>
      <my-nav-bar :title="'MDT远程康复指导服务'"/>
      <container-list :empty-desc="''" :empty="empty" :loading="loading">
        <div v-for="(item) in datas" :key="item.groupId" style="padding: 16px">
          <div class="item-warp" style="position: relative"
               @click="mdtItemClick(item)">
            <div style="font-size: 32px;color: black;padding: 16px;font-weight: bolder">{{ item.name }}</div>
            <div
              style="font-size: 20px;color: #6b6b6b;padding-left: 16px;font-weight: normal;position: absolute;bottom: 16px">
              服务群ID：{{
                item.jMessageGroupId
              }}({{ item.groupId }})
            </div>
          </div>
        </div>
      </container-list>
    </div>
  </div>
</template>

<script>
import ContainerList from '../../components/ContainerList'
import MyNavBar from '../../components/MyNavBar'
import MdtDao from '../../network/MdtDao'

export default {
  name: 'MdtHome',
  components: {
    ContainerList,
    MyNavBar
  },
  data () {
    return {
      empty: true,
      loading: true,
      datas: [],
      uid: null,
      userAccount: null
    }
  },
  mounted () {
    this.userAccount = this.$store.state.user.profile.userId
    this.uid = parseInt(this.$store.state.user.profile.phone.slice(2))
    this.loadDatas()
  },
  methods: {
    async httpDatas () {
      this.datas = await MdtDao.list()
    },
    async loadDatas () {
      this.loading = true

      await this.httpDatas()
      console.log('数据请求结果', this.datas)
      if (this.datas.length) {
        this.empty = false
      } else {
        this.empty = true
      }
      this.loading = false
    },
    mdtItemClick (item) {
      this.toMdtVideo(item)
    },
    toMdtVideo (mdt) {
      const query = `uid=${this.uid}&channel=${mdt.jMessageGroupId}&name=${mdt.name}&userAccount=${this.userAccount}`
      const url = `/mdt/video?from=safari&${query}`
      const ulrWarning = `/mdt/video/warning?${query}`
      // const url = ulrWarning
      if (this.isIOS()) {
        this.$store.commit('device/setIsIOS', true)
        if (!this.checkIosVer(12.2)) {
          this.$toast(`系统版本为${this.iosVer},低于要求版本(12.2),无法使用远程视频功能`)
          return
        }
        console.log('版本大于12.2')
        // 若是iOS系统，判断是否是微信；（微信环境下，需要系统版本≥14.3；非微信环境下，需要系统≥12.2）
        if (this.isWeixin()) {
          if (this.checkIosVer(14.3)) {
            this.$toast('微信环境，版本大于14.3，进入MDT')
            this.$router.push(url)
          } else {
            this.$router.push(ulrWarning)
            this.$toast(`系统版本为${this.iosVer},低于要求版本(14.3),请从系统浏览器打开`)
          }
        } else {
          console.log('非微信环境，直接进入MDT')
          // 非微信环境，就是在Safari浏览器，且大于12.2，可以直接进入
          this.$router.push(url)
        }
      } else {
        // 只要不是ios系统，都可以直接进入
        this.$router.push(url)
      }
    },
    isWeixin () {
      const { userAgent } = navigator
      const isWeixin = !!/MicroMessenger/i.test(userAgent)
      return isWeixin
    },
    checkIosVer (ver) {
      const { userAgent } = navigator
      this.iosVer = userAgent
      // this.$toast(this.iosVer)
      var reg = /OS\s(.+?)\slike\sMac\sOS/g
      // var list = []
      var result = null
      result = reg.exec(userAgent)
      // console.log(result)
      // do {
      //   result = reg.exec(userAgent)
      //   result && list.push(result[1])
      // } while (result)
      // console.log('ios版本', result[1])
      this.iosVer = result[1]
      var reg1 = new RegExp('_', 'g')
      this.iosVer = this.iosVer.replace(reg1, '.')
      this.$store.commit('device/setIosVer', parseFloat(this.iosVer))
      console.log('ios版本', this.iosVer)
      if (parseFloat(this.iosVer) >= ver) {
        return true
      } else {
        return false
      }
    },
    isIOS () {
      const { userAgent } = navigator
      console.log(userAgent)
      if ((userAgent.match(/iPhone/i) || userAgent.match(/iPad/i))) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.page-box {
  background-color: #e1f3f8;
  height: 100vh;
}

.item-warp {
  width: 100%;
  height: 20vh;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(51, 51, 51, 0.7);
}
</style>
