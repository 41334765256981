<template>
  <div>
    <base-title-bar-scroll-page ref="container" :show-title-bar="!isApp" title="视频播放">
      <div v-if="loading"></div>
      <div v-else>
        <video :poster="courseDetail.coverUrl" :src="courseDetail.videoUrl" autoplay controls
               style="width: 100%;max-height: 93vh"></video>
        <div style="padding-left: 16px;padding-right: 16px">
          <div style="font-size: 16px;font-weight: bold">当前播放:</div>
          <div style="font-size: 16px;">《 {{ courseDetail.title }}》</div>
          <div style="font-size: 16px;font-weight: bold">动作指导：</div>
          <div style="white-space: pre-wrap;font-size: 16px">{{ courseDetail.text }}</div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import PlanDao from '@/h5/doucan/network/PlanDao'

/**
 * test ：
 * http://192.168.0.119:8094/doucan/#/CourseDetailVideoPlayPage?courseId=e5ec334d-1023-4b52-9a0d-21432fc975cb&isApp=true&userId=1
 */
export default {
  name: 'CourseDetailVideoPlayPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      loading: true,
      isApp: false,
      userId: null,
      courseId: null,
      courseDetail: null
    }
  },
  mounted () {
    this.courseId = this.$route.query.courseId
    this.userId = this.$route.query.userId
    this.isApp = this.$route.query.isApp
    console.log('userId', this.userId)
    console.log('isApp', this.isApp)

    this.loadCourseDetail()
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    loadCourseDetail () {
      PlanDao.loadCourseForApp(this.courseId).then(res => {
        this.courseDetail = res
        console.log('courseDetail', this.courseDetail)
        this.loading = false
      })
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
