<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="远程指导记录"/>
    <div style="margin-top: 56px">

      <div style="margin-top: 56px">
        <!--  医生列表 用vant组件 -->
        <!--  loading  -->
        <div v-show="!datas" style="text-align: center;margin-top: 16px">
          <van-loading/>
        </div>
        <div v-if="datas" style="padding: 16px">
          <div style="font-size: 20px">远程指导记录次数: {{ datas.length }}</div>
        </div>
        <van-cell-group v-show="datas">
          <van-cell
            v-for="(item, index) in datas"
            :key="index"
            :label="item.mdtId?`MDT: ${item.mdtName}`:`一对一: ${item.patient} - ${item.doctor}`"
            :title="`日期: ${item.startTime}`"
            @click="onItemClick(item)"
          />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'
import DateUtils from '@/utils/DateUtils'

export default {
  name: 'AdminHospitalRemoteGuideList',
  components: { MyNavBar },
  data () {
    return {
      datas: null
    }
  },
  mounted () {
    this.loadDatas()
  },
  methods: {
    async loadDatas () {
      const datas = await AdminHospitalDao.remoteGuideList()
      console.log(datas)
      this.datas = datas
    },
    onItemClick (item) {

    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
