<template>
  <div>
    <MyNavBar :title="'服务人员'"/>
    <MyLoading v-if="loading"/>
    <div v-else style="padding: 16px">
      <div style="text-align: center">若无专门服务人员，则选择【康复助理】</div>
      <van-grid :border="false" :column-num="3">
        <van-grid-item v-for="(item) in doctors" :key="item.userId">
          <div
            :style="item.selected?'background-color: #00b667':'background-color: #f6f6f6'"
            style="width: 100%;height: 100%;text-align: center;border-radius: 8px"
            @click="onItemClick(item)">
            <div style="padding: 16px">
              <!--              <img :src="item.avatar" style="width: 18vw;height: 18vw;overflow:hidden;">-->
              <van-image
                :src="item.avatar"
                fit="cover"
                height="18vw"
                round
                width="18vw"
              />
              <div style="font-size: 18px;margin-top: 8px">{{ item.name }}</div>
              <div style="font-size: 12px;font-weight: bold">{{ item.position }}</div>
              <div v-if="hospitalId===1" style="font-size: 12px;">{{ item.hospitalName }}</div>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
      <div style="height: 80px"></div>
      <MyButton :title="'下一步'"
                style="position: fixed;bottom: 16px;left:16px;right:16px;border-radius: 24px;box-sizing: border-box;display:block;margin-top: 32px"
                @onClick="submit"/>
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import RegisterDao from '../../network/RegisterDao'
import MyLoading from '../../components/MyLoading'
import MyButton from '../../components/MyButton'
import { Notify } from 'vant'

export default {
  name: 'DoctorSelectPage',
  components: {
    MyButton,
    MyLoading,
    MyNavBar
  },
  data () {
    return {
      loading: true,
      doctors: [],
      selected: null,
      hospitalId: null
    }
  },
  mounted () {
    this.hospitalId = this.$store.state.login.register.registHospitalId
    this.loadDoctors()
  },
  methods: {
    async loadDoctors () {
      var registerDoctors = await RegisterDao.getRegisterDoctor(this.$store.state.login.register.registHospitalId)
      console.log('医生列表', registerDoctors)
      registerDoctors.forEach(e => {
        e.selected = false
      })
      this.doctors = registerDoctors
      this.loading = false
    },
    onItemClick (item) {
      console.log(item)
      if (this.selected) {
        this.selected.selected = false
      }
      item.selected = true
      this.selected = item
    },
    submit () {
      if (!this.selected) {
        Notify('请选择一位服务人员')
        return
      }
      this.$store.commit('login/setDoctor', this.selected.userId)
      console.log('选医生', this.$store.state.login)
      this.$router.push('/login/sickKindSelect')
    }
  }
}
</script>

<style scoped>

</style>
