<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <div v-if="checking"></div>
    <div v-else>
      <MyNavBar :back-text="backText" :v-show="!isApp" back style="position:fixed;top: 0;width: 100%" title="康复辅具"
                @onBackClick="onBackClick"/>
      <div style="margin-top: 46px">
        <div>
          <form action="/search" method="GET">
            <van-search v-model="searchValue" placeholder="请输入搜索关键词" show-action
                        @search="onSearch">
              <template #action>
                <van-button style="height: 34px;margin: 0px" type="primary" @click="onSearch">搜索</van-button>
              </template>
            </van-search>
          </form>
          <!--        <div>搜索</div>-->
        </div>
        <div style="display: flex;">
          <div style="width: 30vw;background-color: #f3f3f3;height: 95vh">
            <div>
              <div v-for="(item) in catalogList" :key="item.id"
                   style="height: 56px;text-align: center;line-height: 56px;display: flex;flex-direction: row"
                   @click="onCatalogClick(item)">
                <div :style="{backgroundColor: item.selected?'#00b74f':'#b7b7b7'}"
                     style="height: 56px;width: 8px;"></div>
                <div :style="{backgroundColor: item.selected?'#00b74f':'#e0e0e0'}" style="flex: 1"> {{
                    item.name
                  }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="width: 70vw;background-color: #ffffff;height: 95vh; /* 设置右侧高度为视口高度 */overflow: hidden">
            <!--  商品列表 使用vant -->
            <!--    <van-list :finished="finished" finished-text="没有更多了">-->
            <div style=" max-height: 100%;overflow-y: auto; /* 允许内容溢出时显示垂直滚动条 */">
              <div v-for="(item, index) in goods" :key="index">
                <van-card
                  :desc="item.brief"
                  :num="item.stock"
                  :origin-price="item.originPrice"
                  :price="item.price"
                  :thumb="item.smallPicUrl"
                  :title="item.name"
                  @click="handleGoodClick(item)"
                >
                  <template #footer>
                    <van-button size="mini">查看详情</van-button>
                    <!--            <van-button size="mini">按钮</van-button>-->
                  </template>
                </van-card>
                <div style="height: 16px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <van-popup v-model="isShowDeliveryToBed" closeable position="bottom">
          <div style="padding: 16px">
            <img alt="Your Image Description" src="https://cdn.keihong.tech/img/202312181748503.png"
                 style="width: 100%;display: block;margin-top: 32px"/>
            <div style="height: 16px"></div>
            <div style="font-size: 18px">
              💖“必备辅具”板块下的辅具是在住院手术/康复期间的刚需辅具，我们将提供免费送货上病房服务。
            </div>
            <div style="height: 16px"></div>
            <div style="font-size: 18px">
              🔔如您正在支持送货上病房的医院住院，可在2~24小时内为您免费送达病房。
            </div>
            <div style="height: 32px"></div>
            <div
              style="font-size: 18px;text-align: center;background-color: #136b59;padding: 16px;border-radius: 16px;color: white">
              <div style="margin-bottom: 16px">服务详情请致电咨询</div>
              <a :href="'tel:' + kefuphoneNumber" style="color: white">客服电话：{{ kefuphoneNumber }}</a>
              <div style="font-size: 14px">（点击上方电话号码可直接拨打📳）</div>

            </div>
          </div>
        </van-popup>
        <van-popup v-model="showSearchResult" closeable position="bottom">
          <div style="max-height: 80vh;min-height: 50vh;padding-top: 48px">
            <div v-if="isSearching" style="margin-top: 32px;display: flex;align-items: center;flex-direction: column">
              <div class="loader-2"></div>
              <div>
                搜索中...
              </div>
            </div>
            <div v-if="searchResult.length>0">
              <div style="height: 100%;width: 100%;">
                <div v-for="(item, index) in searchResult" :key="index">
                  <van-card
                    :desc="item.brief"
                    :num="item.stock"
                    :origin-price="item.originPrice"
                    :price="item.price"
                    :thumb="item.smallPicUrl"
                    :title="item.name"
                    @click="handleGoodClick(item)"
                  >
                    <template #footer>
                      <van-button size="mini">查看详情</van-button>
                      <!--            <van-button size="mini">按钮</van-button>-->
                    </template>
                  </van-card>
                  <div style="height: 16px"></div>
                </div>
              </div>
            </div>
          </div>
        </van-popup>
        <div v-if="distributor"
             style="position: fixed;top:0;right:0;z-index: 10;background-color: #ff8000;color: white">
          分销者模式
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'

export default {
  name: 'MallMainPageNeimeng',
  components: { MyNavBar },
  data () {
    return {
      checking: true,
      isApp: false,
      searchValue: '',
      showSearchResult: false,
      searchResult: [],
      isSearching: true,
      kefuphoneNumber: '18902270462',
      finished: false,
      loading: false,
      from: '',
      backRoute: '',
      goods: [],
      backText: '',
      fromToolKepu: false,
      hospitalName: '',
      catalogList: [],
      currentCatalog: null,
      isShowDeliveryToBed: false,
      distributor: null
    }
  },
  beforeMount () {

  },
  mounted () {
    // 读取query参数
    const query = this.$route.query
    if (query.from === 'doctorapp') {
      this.isApp = true
      // this.$dialog({
      //   title: '提示',
      //   message: '商城为辅具展示用途，请勿在APP内下单',
      //   confirmText: '知道了'
      // })
      // 页面重定向到：https://doucan.jianerkanggz.cn/#/ToolKepuHomePage?from=doctorapp
      this.$router.replace('/ToolKepuHomePage?from=doctorapp')
    } else {
      this.checking = false
    }

    if (query.from === 'toolkepu') {
      this.fromToolKepu = true
      this.backText = '返回'
    } else {
      this.backText = '康复圈'
    }
    if (query.hospital) {
      this.hospitalName = query.hospital
    }
    // if (this.from) {
    //   if (this.from === 'square') {
    //     // 从康复圈页面进来（常规渠道）
    //     this.backRoute = '' // 返回按钮直接是返回
    //     return
    //   }
    //   // 从独立的商城二维码进来
    //   if (this.from === 'qrCode') {
    //     return
    //   }
    //   // 从科普文章进来(有专门的产品)
    //   if (this.from === 'article') {
    //
    //   }
    // }

    if (query.distributor) {
      this.distributor = this.$store.state.distributor.data
      console.log('分销者模式', this.distributor)
    }

    this.loadCatalogs()
    // this.loadGoods()
  },
  methods: {
    onSearch (event) {
      if (event.preventDefault) {
        event.preventDefault()
      } // 阻止表单提交
      if (!this.searchValue) {
        return
      }
      console.log('搜索', this.searchValue)
      this.searchResult = []
      this.isSearching = true
      this.showSearchResult = true
      const _that = this
      MallDao.search(this.searchValue).then(res => {
        if (res.length === 0) {
          this.showSearchResult = false
          this.isSearching = false
          this.$dialog({
            title: '提示',
            message: '未找到相关商品',
            confirmText: '知道了'
          })
          return
        }
        _that.searchResult = res
        console.log('搜索结果', _that.searchResult)
        this.isSearching = false
      })
    },
    onBackClick () {
      console.log('返回')

      if (this.fromToolKepu) {
        this.$router.back()
        return
      }

      if (this.distributor) {
        this.$router.back()
        return
      }

      this.$router.replace('/home/square')
    },
    onCatalogClick (item) {
      this.currentCatalog.selected = false
      item.selected = true
      this.currentCatalog = item
      this.loadGoods()
    },
    loadCatalogs () {
      if (!this.hospitalName) {
        this.hospitalName = this.$store.state.user.qrCodeHospital
      }
      console.log('hospitalName', this.hospitalName)
      if (this.hospitalName !== '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
        this.hospitalName = '动优康复'
        if (!this.distributor) {
          this.isShowDeliveryToBed = true
        }
      }
      MallDao.cataloglist(this.hospitalName).then(res => {
        console.log('catalogs', res)
        res.forEach(item => {
          item.selected = false
        })
        res[0].selected = true
        this.currentCatalog = res[0]
        this.catalogList = res
        this.loadGoods()
      })
    },
    async loadGoods () {
      console.log('读取商品目录下的产品： cata id = ' + this.currentCatalog.id)
      this.goods = await MallDao.listByCatalog(this.currentCatalog.id)
      if (this.hospital !== '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
        // 倒序 goods
        this.goods = this.goods.reverse()
      }
      console.log('goods', this.goods)
    },
    handleGoodClick (item) {
      // 跳转到MallDetailPage: /MallDetailPage?goodId=item.id
      if (this.distributor) {
        this.$router.push(`/MallDetailPage?from=malllist&goodId=${item.id}&distributor=true`)
      } else {
        this.$router.push(`/MallDetailPage?from=malllist&goodId=${item.id}`)
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-2 {
  position: relative;
  width: 48px;
  height: 48px;
  border: 3px solid #00afee;
  border-radius: 50%;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #90cf5b;
  }
}

.van-search__action {
  padding: 0;
  line-height: 0;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
