<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div style="display: flex;height: 100vh;flex-direction: column">
    <MyNavBar :title="'我的订单'"/>
    <div v-if="loading">加载中...</div>
    <div v-else-if="orders.length>0" style="flex: 1;overflow-y: scroll;background-color: #eaeaea">
      <!--   显示订单   -->
      <div v-for="(order, index) in orders" :key="index" style="margin: 10px 0">
        <div style="background-color: white;padding: 10px">
          <div style="display: flex;justify-content: space-between">
            <div>订单编号：{{ order.orderId }}</div>
            <div>{{ order.status }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>下单时间：{{ order.date }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>收件人：{{ order.name }}</div>
            <div>联系电话：{{ order.phone }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>收件地址：{{ order.address }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>快递单号：{{ order.logisticsCode }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>快递公司：{{ order.logisticsCompany }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>商品名称：{{ order.goodsName }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>商品数量：{{ order.count }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>商品单价：{{ order.price }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>商品总价：{{ order.amount }}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div>运费：{{ order.freight }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>当前手机号（{{ phone }}）没有订单</div>
    <DialogTextInput ref="dialogPhone" :dialogTitle="'请输入手机号'" :inputLabel="'手机号'"
                     :inputPlaceholder="'请输入手机号'"
                     @submit="onPhoneInput"/>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'
import DialogTextInput from '@/h5/doucan/components/DialogTextInput.vue'

export default {
  name: 'OrderHomePage',
  components: {
    DialogTextInput,
    MyNavBar
  },
  data () {
    return {
      loading: true,
      phone: '',
      orders: null
    }
  },
  mounted () {
    this.getMallOrders()
  },
  methods: {
    getMallOrders () {
      if (!this.phone) {
        // 已登录用户
        if (this.$store.state.user.profile.phone) {
          this.phone = this.$store.state.user.profile.phone
        } else {
          // 未登录，仅下单的用户（从下单逻辑中的地址中的phone）
          this.phone = this.$store.state.mall.addressInfo.phone
        }
      }

      if (!this.phone) {
        //   提示登录或输入查询的手机号
        // 弹出手机号输入框
        // this.showPhoneInput = true
        this.$refs.dialogPhone.openDialog()
        return
      }

      MallDao.getOrders(this.phone).then(res => {
        console.log(res)
        this.orders = res
        this.loading = false
      })
    },
    onPhoneInput (phone) {
      this.phone = phone
      console.log('输入的手机号', phone)
      this.getMallOrders()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
