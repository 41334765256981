<template>
  <div style="width: 100%">
    <my-nav-bar :title="'支付订单信息'" back back-text="康复圈" @onBackClick="onBackClick"/>
    <div class="order-details">
      <div class="order-details__content">
        <div class="order-details__item">
          <strong>订单编号：</strong> {{ payInfo.outTradeNo }}
        </div>
        <!--        <div class="order-details__item">-->
        <!--          <strong>活动名称：</strong> {{ orderData.fromActivity }}-->
        <!--        </div>-->
        <div class="order-details__item">
          <strong>产品名称：</strong> {{ payInfo.goodName }}
        </div>
        <div class="order-details__item">
          <strong>收件人：</strong> {{ mailInfo.name }}
        </div>
        <div class="order-details__item">
          <strong>电话：</strong> {{ mailInfo.phone }}
        </div>
        <div class="order-details__item">
          <strong>地址：</strong> {{ `${mailInfo.province} ${mailInfo.city} ${mailInfo.address}` }}
        </div>
        <div class="order-details__item">
          <strong>支付金额：</strong> ¥{{ payInfo.totalPrice }}
        </div>
        <div class="order-details__item">
          <strong>支付时间：</strong> {{ formatTime(new Date()) }}
        </div>
      </div>
    </div>
    <div>
      <img
        src="https://cdn.keihong.tech/typora/2023-06-18-%E5%88%87%E5%9B%BE%205%20-1-.png"
        style="max-width: 100vw"
      />
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'MallPayResultPage',
  components: {
    MyNavBar
  },
  data () {
    return {
      payInfo: null,
      mailInfo: null
    }
  },
  mounted () {
    const query = this.$route.query
    if (query) {
      this.payInfo = query.payInfo
      this.mailInfo = query.mailInfo
    }
  },
  methods: {
    onBackClick () {
      // 跳转到 home/square
      this.$router.replace('/home/square')
    },
    formatTime (timestamp) {
      // 毫秒时间戳
      const date = new Date(timestamp)
      const formattedDate = date
        .toLocaleString('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        })
        .replace(
          /(\d{4})\D+(\d{2})\D+(\d{2})\D+(\d{2})\D+(\d{2})/,
          '$1年$2月$3日 $4:$5'
        )
      return formattedDate
    }
  }
}
</script>

<style scoped>
.order-details {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.order-details__title {
  font-size: 24px;
  margin-bottom: 10px;
}

.order-details__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-details__item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}
</style>
